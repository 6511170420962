<template>
    <div :class="`wrapper wrapper--default m-3 p-2`">
        <div class="text-center p-2">
            <h3>
                Inkoop provisies jaar: {{ year }}, maand: {{ month }}
            </h3>
            <span @click="previousMonth"><i class="fas fa-arrow-left"></i></span>
            <span class="text-center m-2">Maand: {{ month }}</span>
            <span @click="nextMonth"><i class="fas fa-arrow-right"></i></span>
        </div>
        <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
        <Loading v-if="loading" />
        <table v-if="!loading" :class="`table--perweek table__border--default responsive w-100`">
            <thead class="table__head--default">
                <tr>
                    <th class="table__head--text">Itemnummer</th>
                    <th class="table__head--text">Rekening</th>
                    <th class="table__head--text">Naam</th>
                    <th class="table__data--right table__head--text">Doctype</th>
                    <th class="table__data--right table__head--text">Datum</th>
                    <th class="table__data--right table__head--text">Omschrijving 1</th>
                    <th class="table__data--right table__head--text">Omschrijving 2</th>
                    <th class="table__data--right table__head--text">Bedrag</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in buy_provisions_list" :key="key" class="table__row--color">
                    <td class="table__cell--default">{{ item.itemnummer }}</td>
                    <td class="table__cell--default">{{ item.Rekening }}</td>
                    <td class="table__cell--default">{{ item.Naam }}</td>
                    <td class="table__data--right table__cell--default">
                        {{ item.Docnummer }}
                    </td>
                    <td class="table__data--right table__cell--default">
                        {{ item.Datum }}
                    </td>
                    <td class="'table__data--right table__cell--defaul'">
                        {{ item.Omschrijving1 }}
                    </td>
                    <td  class="'table__data--right table__cell--defaul'">
                        {{ item.Omschrijving2 }}
                    </td>
                    <td class="table__cell--default">
                        € {{ item.Bedrag }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import moment from "moment";
import { subtractTime, addTime } from "@/functions/date.js";
import * as XLSX from "xlsx";

export default {
    components: { Loading },
    data: () => ({
        loading: null,
        buy_provisions_list: null,
        first_day: null,
        last_day: null,
        full_date: moment(),
        month: null,
        year: null,
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.month = moment(this.full_date).month() + 1;
            this.first_day = moment(this.full_date)
                .startOf("month")
                .format("YYYY-MM-DD");
            this.last_day = moment(this.full_date)
                .endOf("month")
                .format("YYYY-MM-DD");
            this.year = moment(this.full_date).year();
            request(
                `buy-provisions-list/${this.first_day}/ ${this.last_day}`,
                "GET"
            ).then(({ buy_provisions_list }) => {
                this.buy_provisions_list = buy_provisions_list;
                this.loading = false;
            });
        },

        previousMonth() {
            this.full_date = subtractTime(1, this.full_date, "months", "YYYY/MM/DD");
            this.getData(this.bu);
        },

        nextMonth() {
            this.full_date = addTime(1, this.full_date, "months", "YYYY/MM/DD");
            this.getData(this.bu);
        },

        createExcel() {
            let item_buy_provisions = [];
            this.buy_provisions_list.forEach((i) => {
                item_buy_provisions.push({
                    "Item": i.itemnummer,
                    "Rekening": i.Rekening,
                    "Naam": i.Naam,
                    "Doctype": i.Docnummer,
                    "Datum": i.Datum,
                    "Omschrijving 1": i.Omschrijving1,
                    "Omschrijving 2": i.Omschrijving2,
                    "Bedrag": i.Bedrag,
                });
            });

            var telWS = XLSX.utils.json_to_sheet(item_buy_provisions);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                wb,
                telWS,
                `Inkoop_provisies_${this.moment().format("DD-MM-YYYY")}`
            );
            XLSX.writeFile(
                wb,
                `Inkoop_provisies_${this.first_day}_${this.last_day}.xlsx`
            );
        },
    },
};
</script>